@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

.show {
  display: block;
}

.hide {
  display: none;
}

//Color Palettes
$primary: #61b9c3;
$secondary: #61b9c3;

// $primary: rgb(31, 150, 198);
// $secondary: rgb(173, 173, 173);

$tertiary: rgb(84, 56, 144);
$text-primary: rgb(96, 101, 109);
$text-secondary: rgb(114, 114, 114);

$primary-dark: rgb(32, 138, 113);
$secondary-dark: rgb(26, 123, 161);
$tertiary-dark: rgb(68, 46, 116);

$primary-light: rgba(42, 179, 146, 0.8);
$secondary-light: rgba(31, 150, 198, 0.8);
$tertiary-light: rgba(84, 56, 144, 0.8);

h2 {
  color: $text-primary;
  font-weight: 700;
  font-size: 28px;
}
p {
  color: $text-primary;
  font-weight: 400;
  font-size: 16px;
}
