@use "sass:math";
@import "../../../index.scss";

$base-spacing-unit: 24px;
$half-spacing-unit: math.div($base-spacing-unit, 2);

$color-alpha: #1772ff;
$color-form-highlight: #eeeeee;

.team {
  // .show-drawer {
  //   position: relative;
  //   margin-top: -20px;
  //   margin-left: -20px;
  //   cursor: pointer;

  //   .show-drawer-icon {
  //     color: $primary;
  //     transform: scale(2);
  //     margin: 0 5px;
  //   }
  // }

  .infos {
    color: rgb(100, 100, 100);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .right-infos {
      display: flex;
      gap: 30px;

      .start-icons {
        transform: scale(1.2);
        margin-right: 3px;
      }
    }
  }

  .user-details {
    .table {
      // width: 100%;
      border: 1px solid $color-form-highlight;
    }

    .table-header {
      display: flex;
      width: 100%;
      background: $primary;
      font-size: 14px;
      font-weight: 700;
      padding: 5px;
    }

    .table-data1,
    .header__item1 {
      flex: 3;
      padding-left: 20px; // background-color: red;
    }
    .table-data2,
    .header__item2 {
      flex: 3;
      // background-color: green;
    }
    .table-data3,
    .header__item3 {
      flex: 4;
      // background-color: purple;
    }
    .table-data4,
    .header__item4 {
      flex: 3;
      // background-color: lightsalmon;
    }
    .table-data5,
    .header__item5 {
      flex: 1;
      text-align: center;
      // background-color: lightgreen;
    }

    .table-row {
      display: flex;
      width: 100%;
      padding: 5px;
      font-size: 14px;
      font-weight: 500;
      color: rgb(100, 100, 100);

      &:nth-of-type(odd) {
        background: $color-form-highlight;
      }
    }

    .filter__link {
      color: white;
      text-decoration: none;
      position: relative;
      display: inline-block;
      // padding-left: $base-spacing-unit;
      // padding-right: $base-spacing-unit;

      &::after {
        content: "";
        position: absolute;
        right: -($half-spacing-unit * 1.5);
        color: white;
        font-size: $half-spacing-unit;
        top: 50%;
        transform: translateY(-50%);
      }

      &.desc::after {
        content: "(desc)";
      }

      &.asc::after {
        content: "(asc)";
      }
    }
  }

  .no-more-text {
    margin-top: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: rgb(200, 200, 200);
  }
}
