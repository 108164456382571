@import '../../index.scss';

.panel-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 100px;
  right: 17px;
  
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  width: 375px;
  max-height: 75%;

  .main-header {
    background: linear-gradient(to right, $secondary-light, $primary-light);
    width: 375px;
    padding-top: 7px;
    height: 65px;
    border-radius: 12px 12px 0 0;
  }
  
  .main-title {
    color: white;
    font-size: 21px;
    font-weight: 600;
    margin: 3%;
    text-align: center;
  }

  .main-form {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 10px;
    height:400px;
  }

  .input-label {
    font-size: 15px;
    color: rgba($text-secondary, 0.9);
    margin: 10px 0px 3px 0px;
  }
  
  .subject-input{
    width: 100%;
    border: 2px solid rgba($text-secondary, 0.9);
    border-radius: 5px;
    height: 35px;
  }

  .description-input{
    width: 100%;
    border: 2px solid rgba($text-secondary, 0.9);
    border-radius: 5px;
    height: 130px;
  }
  

  .button-filled {
    border-radius: 6px;
    border: none;
    background-color: rgb(103, 185, 194);
    width: 100%;
    padding: 5px;
    color: #fff;
    font-size: 15px;
    margin-top: 7%;
    line-height: 25px;
    font-weight: 600;
  
    cursor: pointer;
    &:hover {
      background-color: rgb(88, 159, 167);
    }
  }

  .loginBtn {
    display: flex;
    gap: 20px;
  }



  .main-text {
    color: rgba($text-secondary, 0.9);
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .step-three{
    width: 300px;
  }
}


.icon-style{
  color: white;
}

.upload-icon-style{
  color: black;
  margin-right: 10px;
}

.icon-btn{
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: none;
  background: linear-gradient(to right, $secondary, $primary);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}


