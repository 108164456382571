@import "../../index.scss";

ul {
  list-style-type: none;
}
.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  min-width: 300px;
  // padding-bottom: 100px;

  .allLinks {
    text-decoration: none;
    color: rgb(170, 170, 170);
    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }

  .menu-item {
    padding: 10px 0;
    padding-left: 40px;

    i {
      width: 15%;
    }
    .text {
      margin-left: 20px;
    }

    .logout-icon {
      border-left: 8px solid rgb(255, 255, 255);
      font-size: 23px;
      margin-right: -3px;
    }

    .logout-text {
      margin-left: 30px;
    }
  }

  .menu-icon {
    font-size: 23px;
  }

  .active {
    color: $primary;
    border-left: 8px solid $primary;
    &:hover {
      color: $primary;
    }
  }
  .inactive {
    border-left: 8px solid rgb(255, 255, 255);
    &:hover {
      color: $primary;
    }
  }

  .logo {
    margin: 0 auto;
    padding: 20px 0;
  }
  .avatar-area {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #eaf5f3;
    padding: 10px;
    padding-left: 40px;
    img {
      width: 50px;
      clip-path: circle();
    }

    .details {
      flex: 1 0;
      padding-left: 6px;
      color: #929292;
      .name {
        font-size: 14px;
        font-weight: bold;
      }
      .position {
        font-size: 11px;
        font-weight: 500;
      }
      .company {
        font-size: 11px;
        font-weight: 500;
      }
    }
    .notification {
      display: flex;
      align-self: center;
      display: flex;
      clip-path: ellipse;
      // clip-path: ellipse(45% 25% at 50% 50%);
      // border: 2px solid green;
      font-size: 15px;
      padding: 10px;
      background: $primary;
      color: rgb(255, 255, 255);
      cursor: pointer;
      border-radius: 20px;
    }
    .notification-red {
      display: flex;
      align-self: center;
      display: flex;
      clip-path: ellipse;
      // clip-path: ellipse(45% 25% at 50% 50%);
      // border: 2px solid green;
      font-size: 15px;
      padding: 10px;
      background: $primary;
      color: rgb(255, 0, 0);
      cursor: pointer;
      border-radius: 20px;
    }
  }

  .notify-links {
    // float: left;
    display: absolute;
    text-align: center;
    font-size: 15px;
    color: rgb(73, 169, 182);
    cursor: pointer;
    // margin: auto;
    vertical-align: left;
    // align-items: left;
    text-decoration: none;
    cursor: pointer;
  }

  .notify-menu {
    padding: 0px;
  }
  .cardAction {
    background-color: #f1f1f1;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: gray 0px 0px 10px;
  }
  .notifications-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    background: rgb(99, 185, 195);
    background: linear-gradient(139deg, rgba(99, 185, 195, 1) 0%, rgba(85, 216, 194, 1) 100%);
  }
  .search-area {
    padding: 0 40px;
    padding-top: 25px;
    padding-bottom: 20px;
    text-align: center;

    .form-control:focus {
      box-shadow: 0 0 5px;
    }

    .has-search .form-control {
      padding-left: 2.375rem;
      background-color: #f1f1f1;
      border-radius: 8px;
      border: none;
      color: rgb(170, 170, 170);
    }

    .has-search .form-control-feedback {
      position: absolute;
      z-index: 2;
      display: block;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: rgb(170, 170, 170);
    }
  }
}
