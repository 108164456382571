@import "../../index.scss";

.register-card {
  display: flex;
  flex-direction: row;
  text-align: center;
  min-height: 600px;
  align-self: center;
  margin: 0;
  top: 50%;

  .card-left {
    background-color: rgb(255, 255, 255);
    border-radius: 20px 0 0 20px;
    width: 430px;
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .image {
      display: none;
    }

    .title {
      color: $secondary;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
      display: block;
    }

    .checkbox {
      text-align: left;

      a {
        text-decoration: none;
        font-weight: bold;
      }

      .viewClickTerms {
        font-size: 13px;
        color: $secondary;
        margin-left: 20px;
        text-decoration: none;
      }
    }

    .nextBtn {
      margin-top: 20px;
    }
  }

  #card-right {
    background: linear-gradient(225deg, rgba(17, 40, 38, 1) 40%, rgba(44, 87, 97, 1) 140%);
    border-radius: 0 20px 20px 0;
    width: 430px;
    color: #fff;

    .image {
      text-align: right;
      padding: 20px;
      img {
        width: 120px;
      }
    }

    .texts {
      padding-top: 85px;
      .title {
        padding: 20px 70px;
        font-size: 20px;
        font-weight: 500;
      }

      .description {
        padding: 0 40px 40px 40px;
        font-size: 14px;
        font-weight: 400;
      }

      .login-btn {
        border-radius: 5px;
        margin: 0 70px;
        padding: 5px 5px;
        border: 1px #fff solid;
        font-size: 14px;
        font-weight: 600;

        &:hover {
          background-color: $secondary-dark;
          cursor: pointer;
        }
      }
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

@media (min-width: 400px) and (max-width: 800px) {
  .register-card {
    display: flex;
    flex-direction: column;
    height: auto;

    .card-left {
      border-radius: 20px;
      padding: 50px 60px;

      .image {
        display: block;
        margin-bottom: 20px;
        img {
          width: 140px;
        }
      }
    }

    .card-right {
      border-radius: 20px;
      margin: 10px 0;
      padding: 30px 0;

      .image {
        display: none;
      }

      .title {
        padding-top: 0;
        font-size: 16px;
      }
      .description {
        display: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .register-card {
    margin: 10px 0;
    display: block;

    .card-left {
      width: 200px;
      border-radius: 20px;
      padding: 50px 60px;

      .image {
        display: block;
        margin-bottom: 20px;
        img {
          width: 100px;
        }
      }
    }

    .card-right {
      display: none;
    }
  }
}
