@import "../../index.scss";

.form_wrapper {
  color: $secondary;
  width: 100%;
  margin: 0 auto;
  padding: 2px 0;
  text-align: left;

  label {
    font-size: 13px;
  }
  select {
    width: 100%;
    color: $secondary;
    border: 2px $secondary solid;
    border-radius: 5px;
    &:active {
      border: 2px $secondary solid;
    }
    &:focus {
      border: 2px $secondary solid;
    }
  }

  // &.vert {
  //   flex-direction: column;
  //   & > .dropdown-label {
  //     font-weight: bold;
  //     font-size: large !important;
  //     margin-bottom: 5px;
  //   }
  // }
}
