.integrations {
  .infos {
    color: rgb(100, 100, 100);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .right-infos {
      display: flex;
      gap: 30px;
      margin-left: auto;
      margin-top: 20px;

      .start-icons {
        transform: scale(1.2);
        margin-right: 3px;
      }
    }
  } 

  .dropdown-options {
    background-color: rgb(202, 202, 202);
    border-color: rgb(202, 202, 202);
    width: 200px;
    height: 30px;
    padding: 0px 7px 0px 7px;
    border-radius: 5px;
    border: none;
    outline: none;
  }
}



.fa-circle-minus {
  color: rgb(204, 110, 110);
  cursor: pointer;
  margin-left: 5px;
}

.input-select {
    padding: 5px 10px 5px 5px;
    width: 300px;
    border-radius: 4px;
}

.integration-frame {
    margin-top: 20px;
}

.force-sync-button {
  margin-left: 10px;
  width: 90px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #61b9c3;
}

.force-sync-button:hover {
  background-color: #1a7ba1;
}





