.not-found {
  display: flex;
  justify-content: center;
  flex-direction: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  margin: 0;

  .splash-text{
    font-size: 200px;
    color: lightgrey;
    text-align: center;
    margin-bottom: -40px;
    margin-top: -100px;
  }

  .message{
    color: grey;
    text-align: center;
    width: 500px;
    margin-bottom: 30px;
  }

  .button-group{
    text-align: center;
  }
  
  .button{
    border-radius: 5px;
    margin: 0 10px;
    padding: 5px 10px;
    width:230px;
    background-color: rgba(0,0,0,0);
    color: grey;
    font-weight: 500;
    border: 2px grey solid
  }

  .button:hover{
    border-radius: 5px;
    margin: 0 10px;
    padding: 5px 10px;
    width:230px;
    background-color: rgba(0,0,0,0);
    color: rgb(84, 84, 84);
    font-weight: 600;
    border: 2px rgb(85, 85, 85) solid
  }
}
