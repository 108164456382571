@import '../../index.scss';

.button-filled {
  border-radius: 6px;
  border: none;
  background-color: $secondary;
  width: 100%;
  padding: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 35px;

  cursor: pointer;
  &:hover {
    background-color: $secondary-dark;
  }
}
