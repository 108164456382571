@import "../../index.scss";

.login-card {
  display: flex;
  flex-direction: row;
  text-align: center;
  min-height: 600px;
  align-self: center;
  margin: 0;
  top: 50%;

  .card-left {
    background-color: rgb(255, 255, 255);
    border-radius: 20px 0 0 20px;
    width: 430px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .image {
      display: none;
    }

    #title {
      color: $secondary;
      // font-size: 25px;
      font-weight: 600;
      margin-bottom: 20px;
      // display: block;
    }

    .loginBtn {
      margin-top: 30px;
    }

    .forgotPwd {
      margin-top: 5px;
      text-align: left;
      a {
        text-decoration: none;
        color: $text-primary;
        // font-size: 12px;
        font-style: italic;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  #card-right {
    background: linear-gradient(225deg, rgba(17, 40, 38, 1) 40%, rgba(44, 87, 97, 1) 140%);
    border-radius: 0 20px 20px 0;
    width: 430px;
    color: #fff;

    .image {
      padding-top: 20px;
      padding-right: 20px;
      text-align: right;
      img {
        width: 120px;
      }
    }

    #texts {
      #title {
        padding: 0 70px;
        // // font-size: 24px;
        font-weight: 500;
      }

      #description {
        padding: 20px 40px 40px 40px;
        // // font-size: 14px;
        font-weight: 400;
      }

      .signup {
        border-radius: 5px;
        margin: 0 70px;
        padding: 5px 5px;
        border: 1px #fff solid;
        // // font-size: 14px;
        font-weight: 600;

        &:hover {
          background-color: $secondary-dark;
          cursor: pointer;
        }
      }
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

#buttons {
  .call_btn {
    margin-top: 20px;
    width: 200px;
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 5px 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 1px rgb(84 84 84);
    border: none;
  }
}
@media (min-width: 400px) and (max-width: 800px) {
  .login-card {
    display: flex;
    flex-direction: column;
    height: auto;

    .card-left {
      border-radius: 20px;

      .image {
        display: block;
        margin-bottom: 20px;
        img {
          width: 140px;
        }
      }
    }

    #card-right {
      border-radius: 20px;
      margin: 10px 0;
      padding: 30px 0;

      .image {
        display: none;
      }

      #title {
        padding-top: 0;
        // font-size: 16px;
      }
      #description {
        display: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .login-card {
    display: block;

    .card-left {
      width: 200px;
      border-radius: 20px;

      .image {
        display: block;
        margin-bottom: 20px;
        img {
          width: 100px;
        }
      }
    }

    #card-right {
      display: none;
    }
  }
}
