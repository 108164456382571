$primary: rgb(96, 186, 195);
.rmd {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;

  // Set width of different element types
  // important for correct spacing
  & p {
    width: 100%;
  }

  & table {
    width:  90%;

  }

  & h2, h3, h4 {
    width: 100%;
  }

  & h2, h3 {
    color: #{$primary};
  }
  

  // Setting image centered and half width

  
  & table {
    background: whitesmoke;
    padding: 2px;
  }

  & th {
    text-align: center;
    background-color: #{$primary};
    color: white;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  
  td {
    border: solid 1px white;
    border-style: none solid solid none;
  }

}