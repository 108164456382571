@import '../../index.scss';

.check-box-wrapper {
  padding: 5px 0;
  font-size: 13px;
  color: $secondary;
  display: flex;
  align-items: center;

  input {
    margin-right: 8px;
  }

  a {
    color: $secondary;
    text-decoration: none;
  }
}
