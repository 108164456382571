@import "../../index.scss";

.addressField {
  text-align: left;
  padding: 2px 0;
  color: $secondary;

  .addressField-label {
    font-size: 13px;
  }

  input {
    color: $secondary;
    margin: 1px 0;
    padding-left: 6px;
    border: 2px $secondary solid;
    border-radius: 5px;
    width: 100%;
    line-height: 30px;
    &:focus {
      outline: none;
      border: 2px solid $secondary-dark;
    }
  }

  ul {
    background-color: rgba(241, 241, 241, 0.699);
    font-size: 14px;
    padding: 10px;
    li {
      cursor: pointer;
      &:hover {
        font-weight: 500;
        background-color: rgb(241, 241, 241);
      }
    }
  }
}

.error {
  font-size: 11px;
  color: red;
}
