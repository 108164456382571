@import "../../index.scss";

// .card {
//   // background-image: url("../../images/FaintBackground.jpg");
//   background-color: #f9f9f9;
//   padding: 40px;
//   border-radius: 10px;
// }

.card {
  // position: relative;
  width: 100%;
  padding: 40px;
  border-radius: 10px;
}

.card::before {
  content: "";
  border-radius: 10px;
  background-image: url("../../images/FaintBackground.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  filter: brightness(1.05);
  opacity: 1;
}

.card-content {
  position: relative;
}
