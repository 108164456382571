@import "../../../index.scss";

.dashboard {
  .dropdown-options {
    background-color: rgb(202, 202, 202);
    border-color: rgb(202, 202, 202);
    width: 200px;
    padding: 7px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .secondline {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .year {
    font-size: 12px;
  }
}
