@import "../../index.scss";

.tabs-wrapper {
  margin-top: 5px;

  .first-tab {
    border: 2px solid $primary;
    border-radius: 10px 0 0 10px;
    padding: 5px 20px;
    color: $primary;
    cursor: pointer;

    &:hover {
      background-color: rgba($primary, 0.1);
    }
  }

  .mid-tab {
    border-top: 2px;
    border-right: 2px;
    border-bottom: 2px;
    border-left: 0;
    border-style: solid;
    border-color: $primary;
    border-radius: 0;
    padding: 5px 10px;
    color: $primary;
    cursor: pointer;

    &:hover {
      background-color: rgba($primary, 0.1);
    }
  }

  .last-tab {
    border-top: 2px;
    border-right: 2px;
    border-bottom: 2px;
    border-left: 0;
    border-style: solid;
    border-color: $primary;
    border-radius: 0 10px 10px 0;
    padding: 5px 20px;
    color: $primary;
    cursor: pointer;

    &:hover {
      background-color: rgba($primary, 0.1);
    }
  }

  .active-tab {
    background-color: $primary;
    color: #fff;

    &:hover {
      background-color: rgba($primary, 0.5);
    }
  }
}
