@import "../../index.scss";

.forgot-wrapper {
  background: linear-gradient(90deg, rgba($secondary, 1) 0%, rgba($primary, 1) 70%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .forgot-card {
    margin: 10px;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
}
