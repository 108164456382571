@import '../../index.scss';

.formField {
  text-align: left;
  padding: 2px 0;
  color: $secondary;

  .formField-label {
    font-size: 13px;
  }

  input {
    color: $secondary;
    margin: 1px 0;
    padding-left: 6px;
    border: 2px $secondary solid;
    border-radius: 5px;
    width: 100%;
    line-height: 30px;
    &:focus {
      outline: none;
      border: 2px solid $secondary-dark;
    }
  }
}

.error {
  font-size: 11px;
  color: red;
}
