@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-circle {
    clip-path: circle();
  }

  .custom-gradient {
    background: linear-gradient(360deg, #61b9c3 0%, #61b9c3 0%);
  }

  .custom-button {
    padding: 5px 10px;
    border: 2px solid #61b9c3;
    color: white;
    background-color: #61b9c3;
    border-radius: 10px;
    cursor: pointer;
  }
  .custom-button:hover {
    opacity: 0.85;
  }
}
